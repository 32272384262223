.CaseStudy {
  --s_m_case_study_content_width: 50%;
}

.CaseStudy a {
  transition: var(--s_m_hover_transition);
}

.CaseStudy > .AfterHeroFix {
  margin-top: 0;
}

.ThirdFourthAlign {
  margin-left: var(--s_m_page_h_padding);
  margin-right: calc(2 * var(--s_m_page_h_padding));
}

.DownloadBtn {
  display: none;
}

.ImageHeroLeft {
  align-items: center;
}

.ImageHeroRightBleedTextWrapper {
  width: 50%;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}

.SectionVerticalSpace {
  margin-top: 120px;
  margin-bottom: 120px;
}

.ImageHeroTextWrapper {
  width: 50%;
  margin-left: 51px;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}

.ImageHeroRight {
  flex-direction: row-reverse;
  column-gap: 61px;
  align-items: center;
  justify-content: flex-end;
}

.LeftSkinnyImage {
  height: 474px;
  width: auto;
}

.ImageHeroImage {
  width: 50%;
  height: 100%;
}

.FillContentImage {
  --s_m_case_study_fill_image_width: 86.45vw;
  width: var(--s_m_case_study_fill_image_width);
  margin-left: calc((100vw - var(--s_m_case_study_fill_image_width)) / 2);
  margin-right: calc((100vw - var(--s_m_case_study_fill_image_width)) / 2);
}

.RightBleedImage {
  height: auto;
  width: 50%;
}

@media only screen and (max-width: 1024px) {
  .ImageHeroLeft {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
    justify-content: flex-start;
    row-gap: 50px;
  }

  .ImageHeroRight {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
    justify-content: flex-start;
    row-gap: 50px;
  }

  .ImageHeroTextWrapper {
    margin-left: 0;
    width: 100%;
    row-gap: 25px;
  }

  .LeftSkinnyImage {
    width: auto;
    height: 474px;
  }

  .ImageHeroRightBleedTextWrapper {
    width: 100%;
    max-width: var(--s_m_page_max_width);
    row-gap: 25px;
  }
  .RightBleedImage {
    width: 100%;
    max-width: var(--s_m_page_max_width);
    height: auto;
  }

  .ImageHeroImage {
    width: 100%;
    max-width: var(--s_m_page_max_width);
    height: auto;
  }

  .SectionVerticalSpace {
    margin-top: 100px;
    margin-bottom: 100px;
  }
}

.FillContentImage {
  width: 100%;
  margin-left: 0px;
  margin-right: 0px;
}

.MarginTop {
  margin-top: 60px;
}
